<template>
    <v-layout justify-center>
        <v-responsive max-width="2160" width="100%">
            <v-row class="mt-8" justify="center" align="center">
                <v-col cols="auto" class="pt-0 pb-0 headline text-start">청약신청 목록</v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto" class="pt-0 pb-0">
                    <v-btn class="ma-2" color="secondary" rounded @click="showSearch = !showSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                </v-col>
            </v-row>

            <v-expand-transition>
                <application-search/>
            </v-expand-transition>

            <v-row>
                <v-spacer></v-spacer>
                <v-col cols="auto">
                    <v-btn color="white" class="green--text" @click="excel"> <v-icon>mdi-file-excel</v-icon> 엑셀다운로드 </v-btn>
                </v-col>
            </v-row>
            
            <v-data-table :items="applications" :headers="headers" disable-sort disable-pagination hide-default-footer class="elevation-1">
                <template #[`item.no`]="{ item }">
                    {{ (page - 1) * limit + (+applications.indexOf(item) + 1) }}
                </template>
                <template #[`item.supply_category`]="{ item }">
                    {{ SUPPLY_CATEGORY[item.supply_category]?.text }}
                </template>
                <template #[`item.qualification_type`]="{ item }">
                    {{ QUALIFICATION_TYPE[item.qualification_type]?.text }}
                </template>
                <template #[`item.supply_type`]="{ item }">
                    {{ SUPPLY_TYPE[item.supply_type]?.text }}
                </template>                   
                <template #[`item.region_rank`]="{ item }">
                    {{ RANKING_LIST[item.region_rank]?.text }}
                </template>                   
                <template #[`item.earning_rank`]="{ item }">
                    {{ RANKING_LIST[item.earning_rank]?.text }}
                </template>                   
                <template #[`item.isHomeless`]="{ item }">
                    {{ item.isHomeless ? "예" : "아니요" }}
                </template>                   
                <template #[`item.isAsset`]="{ item }">
                    {{ item.isAsset ? "예" : "아니요" }}
                </template>                   
                <template #[`item.isCar`]="{ item }">
                    {{ item.isCar ? "예" : "아니요" }}
                </template>                   
                <template #[`item.download`]="{ item }">
                    <v-dialog max-width="360px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on">{{ `${Object.values(item.files).filter((item) => item).length} / 4` }}</v-btn>
                        </template>
                        <v-card>
                            <v-card-title>
                                <span class="subtitle-1">첨부파일</span>
                            </v-card-title>
                            <v-divider/>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="12" v-for="(file, index) in Object.values(item.files).filter((item) => item)" :key="index">
                                        <div v-if="file">
                                            <span>{{ `첨부파일 ${index + 1}: `}}</span>
                                            <span style="font-weight: bold;">{{ file.name }}</span>
                                            <v-row no-gutters>
                                                <v-col cols="6">
                                                    <v-btn width="140" :href="file.src" target="_blank"><v-icon class="mr-2">mdi-eye</v-icon>미리보기</v-btn>
                                                </v-col>
                                                <v-col cols="6">
                                                    <v-btn width="140" :href="file.src" :download="file.name"><v-icon class="mr-2">mdi-download</v-icon>다운로드</v-btn>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-dialog>
                </template>
                <template #[`item.createdAt`]="{ item }">
                    {{ $dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss") }}
                </template>
                <template #[`item.actions`]="{ item }">
                    <application-dialog v-bind="{ application: item }" @update="search">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon small v-bind="attrs" v-on="on" title="수정" class="ml-2">mdi-pencil</v-icon>
                        </template>
                    </application-dialog>
                    <v-icon small @click="remove(item)" title="삭제" class="ml-2">mdi-delete</v-icon>
                </template>
            </v-data-table>
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12" @input="(page) => $router.push({ query: { ...$route.query, page } })"></v-pagination>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import XLSX from "xlsx";
import { APPLICATION_TYPE, SUPPLY_CATEGORY, QUALIFICATION_TYPE, SUPPLY_TYPE, RANKING_LIST, BOOLEAN_LIST } from "@/assets/variables";
import ApplicationDialog from "@/components/console/applications/application-dialog.vue";
import ApplicationSearch from "@/components/console/applications/application-search.vue";

export default {
    components: {
        ApplicationDialog,
        ApplicationSearch
    },
    data() {
        return {
            headers: [
                { text: "no", value: "no", align: "center", width: 50 },
                { text: "신청인", value: "name", align: "center", width: 100 },
                { text: "연락처", value: "phone", align: "center", width: 100 },
                { text: "생년월일", value: "birth", align: "center", width: 100 },
                { text: "이메일", value: "email", align: "center", width: 100 },
                { text: "신청자격", value: "qualification_type", align: "center", width: 100 },
                { text: "공급유형", value: "supply_category", align: "center", width: 100 },
                { text: "공급타입", value: "supply_type", align: "center", width: 80 },
                { text: "소득순위", value: "earning_rank", align: "center", width: 80 },
                { text: "지역순위", value: "region_rank", align: "center", width: 80 },
                { text: "무주택 충족", value: "isHomeless", align: "center", width: 80 },
                { text: "자산기준 충족", value: "isAsset", align: "center", width: 80 },
                { text: "자동차가액 충족", value: "isCar", align: "center", width: 80 },
                // { text: "미리보기", value: "preview", align: "center", width: 100 },
                { text: "첨부파일", value: "download", align: "center", width: 100 },
                { text: "신청일자", value: "createdAt", align: "center", width: 100 },
                { text: "actions", value: "actions", align: "center", width: 100 },
            ],
            SUPPLY_CATEGORY,QUALIFICATION_TYPE,SUPPLY_TYPE,RANKING_LIST,
            // page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 10,

            summary: { totalCount: 0 },
            applications: [],
        };
    },
    computed: {
        params() {
            let { query } = this.$route;
            query.code = APPLICATION_TYPE.APPLICATION.value;
            return query;
        },
        page() {
            return +(this.$route.query.page || "1");
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.search();
        },
        async search(routable = true) {
            try {
                // if (routable) {
                //     this.$router.push({
                //         query: Object.assign({}, this.params, { page: this.page }),
                //     });
                // }

                var { summary, applications } = await api.console.application.gets({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit,
                    },
                    params: this.params,
                });

                this.applications = applications;
                this.summary = summary;
                this.pageCount = Math.ceil(summary.totalCount / this.limit);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        async edit(item) {
            this.editItem = Object.assign({}, item);
            
            if(this.editItem.file1) this.editItem.file1 = await api.getResource(this.editItem.file1, true);
            if(this.editItem.file2) this.editItem.file2 = await api.getResource(this.editItem.file2, true);
            this.shows = true;
        },

        async remove(item) {
            try {
                if (confirm("청약신청을 삭제하시겠습니까?")) {
                    
                    await Promise.all[
                        api.console.application.upload.delete({ _id: item._id, name: "file1"}),
                        api.console.application.upload.delete({ _id: item._id, name: "file2"}),
                        api.console.application.upload.delete({ _id: item._id, name: "file3"}),
                        api.console.application.upload.delete({ _id: item._id, name: "file4"})
                    ];

                    await api.console.application.delete(item);
                    await this.search();

                    alert("삭제되었습니다");
                }
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        async excel() {
            try {
                delete this.params.page;
                const { applications } = await api.console.application.gets({
                    params: this.params,
                });

                var rows = applications.map((application) => ({
                    신청일자: this.$dayjs(application?.createdAt).format("YYYY-MM-DD HH:mm:ss"),
                    신청인: application?.name,
                    연락처: application?.phone,
                    생년월일: application?.birth,
                    이메일: application?.email,
                    배우자이름: application?.spouse?.name,
                    배우자연락처: application?.spouse?.phone,
                    배우자생년월일: application?.spouse?.birth,
                    신청자격: QUALIFICATION_TYPE[application?.qualification_type]?.text,
                    공급유형: SUPPLY_CATEGORY[application?.supply_category]?.text,
                    공급타입: SUPPLY_TYPE[application?.supply_type]?.text,
                    소득순위: RANKING_LIST[application?.earning_rank]?.text,
                    지역순위: RANKING_LIST[application?.region_rank]?.text,
                    무주택충족여부: BOOLEAN_LIST[(""+application?.isHomeless)?.toUpperCase()]?.text,
                    자산기준충족여부: BOOLEAN_LIST[(""+application?.isAsset)?.toUpperCase()]?.text,
                    자동차가액충족여부: BOOLEAN_LIST[(""+application?.isCar)?.toUpperCase()]?.text,  
                    첨부파일1: application?.files?.file1 ? `https://lumino816.com/${application?.files?.file1?.src}` : "-",
                    첨부파일2: application?.files?.file2 ? `https://lumino816.com/${application?.files?.file2?.src}` : "-",
                    첨부파일3: application?.files?.file3 ? `https://lumino816.com/${application?.files?.file3?.src}` : "-",
                    첨부파일4: application?.files?.file4 ? `https://lumino816.com/${application?.files?.file4?.src}` : "-",
                }));

                var workbook = new XLSX.utils.book_new();
                var worksheet = XLSX.utils.json_to_sheet(rows);

                XLSX.utils.book_append_sheet(workbook, worksheet, "new");
                XLSX.writeFile(workbook, `청약신청목록_${this.$dayjs().format('YYYY_MM_DD_HHmmss')}.xlsx`);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.email : error.email);
            }
        },
    },
    watch: {
        params() {
            this.search();
        }
    }
};
</script>
