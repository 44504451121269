<template>
    <v-container fluid fill-height class="console-notification">
        <v-layout justify-center>
            <v-responsive max-width="1280px" width="100%">
                <v-row class="mt-8" justify="center" align="center">
                    <v-col cols="auto" class="pt-0 pb-0 headline text-start">서식 다운로드</v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="pt-0 pb-0">
                        <v-btn class="ma-2" color="secondary" rounded @click="showSearch = !showSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                    </v-col>
                </v-row>
                <v-expand-transition>
                    <v-card v-show="showSearch" class="elevation-1 mx-auto">
                        <v-card-title class="subtitle-2 font-weight-bold">검색필터</v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" sm="6" class="py-0">
                                    <v-date-field v-model="filter.createdAt[0]" label="작성일자" persistent-placeholder hide-details></v-date-field>
                                </v-col>
                                <v-col cols="12" sm="6" class="py-0">
                                    <v-date-field v-model="filter.createdAt[1]" label="작성일자" persistent-placeholder hide-details></v-date-field>
                                </v-col>
                            </v-row>
                            <v-row class="mt-3">
                                <v-col cols="6" md="3" class="py-0">
                                    <v-select v-model="filter.searchKey" :items="searchKeys" label="검색조건" hide-details persistent-placeholder></v-select>
                                </v-col>
                                <v-col cols="6" md="3" class="py-0">
                                    <v-text-field v-model="filter.searchValue" label="검색어" persistent-placeholder hide-details :disabled="!filter.searchKey"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" rounded text @click="(page = 1) && search()"><v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-expand-transition>
            
                <v-data-table :headers="downloadsHeaders" :items="downloads" disable-sort disable-pagination hide-default-footer class="elevation-1 mt-4">
                    <template #[`item.no`]="{ item }">
                        {{ (page - 1) * limit + downloads.indexOf(item) + 1 }}
                    </template>
                    <template #[`item.download`]="{ item }" style="height: auto">
                            <v-row @click="$set(item, 'show', !item.show)">
                                <v-col cols="auto" class="text-start">{{ item.subject }}</v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="auto">
                                    <v-icon v-if="!item.show">mdi-chevron-down</v-icon>
                                    <v-icon v-else>mdi-chevron-up</v-icon>
                                </v-col>
                            </v-row>
                            <v-expand-transition>
                                <v-row v-show="item.show">
                                    <v-col cols="12" class="text-start">
                                        <p v-html="item.content.replace(/\n/g, '<br>')"></p>
                                    </v-col>
                                </v-row>
                            </v-expand-transition>
                    </template>
                    <template #[`item.preview`]="{ item }">
                        <v-btn v-if="item.upload" :href="item.upload" icon hide-details target="_blank">
                            <v-icon >mdi-magnify</v-icon>
                        </v-btn>
                    </template>
                    <template #[`item.isNotice`]="{ item }">
                        <v-switch v-model="item.isNotice" class="mt-0" hide-details @change="changeFixed(item)"></v-switch>
                    </template>
                    <template #[`item.createdAt`]="{ item }">
                        {{ item.createdAt.toDate() }}
                    </template>
                    <template #[`item.actions`]="{ item }">
                        <v-icon small class="mr-2" @click="edit(item)">
                            mdi-pencil
                        </v-icon>
                        <v-icon small @click="remove(item)">
                            mdi-delete
                        </v-icon>
                    </template>
                </v-data-table>
                <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12"></v-pagination>

                <v-dialog v-model="downloadDialog" max-width="820px">
                    <template #activator="{ on }">
                        <v-btn bottom color="accent" dark fab fixed right @click="create">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                    <v-card v-if="downloadDialog">
                        <v-card-title>
                            <span v-if="!editItem._id" class="subtitle-1">게시글 등록</span>
                            <span v-else class="subtitle-1">게시글 수정</span>
                        </v-card-title>
                        <v-card-text>
                            <v-row class="mt-3">
                                <v-col class="py-0">
                                    <v-text-field v-model="editItem.subject" label="제목" persistent-placeholder hide-details></v-text-field>
                                </v-col>
                                <v-col cols="12" md="auto" class="py-0">
                                    <div>
                                        <div class="caption">상단고정</div>
                                        <v-switch v-model="editItem.isNotice" class="mt-0" hide-details></v-switch>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row align="center" class="mt-4">
                                <v-col class="py-0 pr-0">
                                    <v-file-input v-model="editItem.upload" label="첨부파일" persistent-placeholder hide-details />
                                </v-col>
                                <v-col class="py-0" cols="auto">
                                    <v-icon>mdi-delete</v-icon>
                                </v-col>
                            </v-row>
                            <div class="caption mt-4 mb-1">내용</div>
                            <naver-smarteditor v-model="editItem.content" rows="10"></naver-smarteditor>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text @click="close">취소</v-btn>
                            <v-btn color="primary" text @click="save(editItem)">저장</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-responsive>
        </v-layout>
    </v-container>
</template>

<script>
import api from "@/api";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
export default {
    components: {
        NaverSmarteditor,
        VDateField
    },
    name: "Notifications",
    created(){
        this.init();
    },
    data() {
        return {
            showSearch: true,

            page: 1,
            pageCount: 0,
            limit: 10,

            downloadDialog: false,
            downloads: [],
            downloadsHeaders: [{
                    text: "no",
                    align: "center",
                    width: 100,
                    value: "no"
                },
                {
                    text: '게시글',
                    align: 'center',
                    value: 'download'
                },
                {
                    text: '미리보기',
                    align: 'center',
                    width: 80,
                    value: 'preview'
                },
                {
                    text: '작성일자',
                    align: 'center',
                    width: 120,
                    value: 'createdAt'
                },
                {
                    text: '상단고정',
                    align: 'center',
                    width: 80,
                    value: 'isNotice'
                },
                {
                    text: 'Actions',
                    align: 'center',
                    width: 100,
                    value: 'actions'
                }
            ],
            editItem: {
                _id: null,
                subject: "",
                content: "",
                upload: null
            },

            defaultItem: {
                _id: null,
                subject: "",
                content: "",
                upload: null
            },
            
            filter: {
                searchKey: this.$route.query.searchKey,
                searchValue: this.$route.query.searchValue,
                createdAt: [(this.$route.query.createdAt || [])[0], (this.$route.query.createdAt || [])[1]],
            },

             searchKeys: [
                { text: "전체", value: null },
                // { text: "작성자", value: "writer.name" },
                { text: "제목", value: "subject" },
                { text: "내용", value: "content" },
            ],
        };
    },
    methods: {
        async init(){
            try{
                await this.search();
            }
            catch(error){
                console.error(error);
            }
        },

        async search(){
            try{
                var { summary, downloads } = await api.console.center.downloads.gets({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit
                    },
                    params: this.filter
                });

                this.downloads = downloads;
                this.pageCount = Math.ceil(summary.totalCount / this.limit);
            }
            catch(error){
                console.error(error);
            }
        },

        close(){
            this.downloadDialog = false;
        },

        create(){
            this.editItem = Object.assign({}, this.defaultItem);
            this.downloadDialog = true;
        },

        async edit(download){
            this.editItem = Object.assign({}, download);
            if(download.upload) this.editItem.upload = await api.getResource(download.upload, true);

            this.downloadDialog = true;
        },

        async save(download){
            try{
                let { download: { _id } } = download._id ? await api.console.center.downloads.put(download) : await api.console.center.downloads.post(download);
                if(download.upload){ 
                    await api.console.center.downloads.upload.post(_id, download.upload);
                }
                
                alert("저장되었습니다.");
                await this.search();
                this.downloadDialog = false;
            } catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
            }
        },

        async remove(download){
            try{
                if(confirm("공지사항을 삭제하시겠습니까?")){
                    await api.console.center.downloads.delete(download);
                    await this.search();
                    alert("삭제되었습니다.");
                }
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
            }
        },
        async changeFixed(item){
            try{
                await api.console.center.downloads.put(item);
                await this.search();
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
            }
        }
    }
}
</script>

<style>
.console-notification .v-data-table__mobile-row { height: auto !important; }
</style>
