var render = function render(){
  var _vm$form;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "800px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "subtitle-1"
  }, [_vm._v("입주 관심 상세")])]), _c('v-divider'), _c('v-card-text', [_c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "이름",
      "hide-details": ""
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "생년월일",
      "hide-details": ""
    },
    model: {
      value: _vm.form.birth,
      callback: function ($$v) {
        _vm.$set(_vm.form, "birth", $$v);
      },
      expression: "form.birth"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "연락처",
      "hide-details": ""
    },
    model: {
      value: _vm.form.phone,
      callback: function ($$v) {
        _vm.$set(_vm.form, "phone", $$v);
      },
      expression: "form.phone"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "이메일",
      "hide-details": ""
    },
    model: {
      value: _vm.form.email,
      callback: function ($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "배우자 이름",
      "hide-details": ""
    },
    model: {
      value: _vm.form.spouse.name,
      callback: function ($$v) {
        _vm.$set(_vm.form.spouse, "name", $$v);
      },
      expression: "form.spouse.name"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "배우자 연락처",
      "hide-details": ""
    },
    model: {
      value: _vm.form.spouse.phone,
      callback: function ($$v) {
        _vm.$set(_vm.form.spouse, "phone", $$v);
      },
      expression: "form.spouse.phone"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "배우자 생년월일",
      "hide-details": ""
    },
    model: {
      value: _vm.form.spouse.birth,
      callback: function ($$v) {
        _vm.$set(_vm.form.spouse, "birth", $$v);
      },
      expression: "form.spouse.birth"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-select', {
    staticClass: "mt-3",
    attrs: {
      "items": _vm.qualification_items,
      "label": "신청자격",
      "hide-details": ""
    },
    model: {
      value: _vm.form.qualification_type,
      callback: function ($$v) {
        _vm.$set(_vm.form, "qualification_type", $$v);
      },
      expression: "form.qualification_type"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-select', {
    staticClass: "mt-3",
    attrs: {
      "items": _vm.category_items,
      "label": "공급유형",
      "hide-details": ""
    },
    model: {
      value: _vm.form.supply_category,
      callback: function ($$v) {
        _vm.$set(_vm.form, "supply_category", $$v);
      },
      expression: "form.supply_category"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-select', {
    staticClass: "mt-3",
    attrs: {
      "items": _vm.type_items,
      "label": "공급타입",
      "hide-details": ""
    },
    model: {
      value: _vm.form.supply_type,
      callback: function ($$v) {
        _vm.$set(_vm.form, "supply_type", $$v);
      },
      expression: "form.supply_type"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-select', {
    staticClass: "mt-3",
    attrs: {
      "items": _vm.rank_items,
      "label": "소득순위",
      "hide-details": ""
    },
    model: {
      value: _vm.form.earning_rank,
      callback: function ($$v) {
        _vm.$set(_vm.form, "earning_rank", $$v);
      },
      expression: "form.earning_rank"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-select', {
    staticClass: "mt-3",
    attrs: {
      "items": _vm.rank_items,
      "label": "지역순위",
      "hide-details": ""
    },
    model: {
      value: _vm.form.region_rank,
      callback: function ($$v) {
        _vm.$set(_vm.form, "region_rank", $$v);
      },
      expression: "form.region_rank"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-select', {
    staticClass: "mt-3",
    attrs: {
      "items": _vm.boolean_items,
      "label": "무주택 충족 여부",
      "hide-details": ""
    },
    model: {
      value: _vm.form.isHomeless,
      callback: function ($$v) {
        _vm.$set(_vm.form, "isHomeless", $$v);
      },
      expression: "form.isHomeless"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-select', {
    staticClass: "mt-3",
    attrs: {
      "items": _vm.boolean_items,
      "label": "자산기준 충족 여부",
      "hide-details": ""
    },
    model: {
      value: _vm.form.isAsset,
      callback: function ($$v) {
        _vm.$set(_vm.form, "isAsset", $$v);
      },
      expression: "form.isAsset"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-select', {
    staticClass: "mt-3",
    attrs: {
      "items": _vm.boolean_items,
      "label": "자동차가액 충족 여부",
      "hide-details": ""
    },
    model: {
      value: _vm.form.isCar,
      callback: function ($$v) {
        _vm.$set(_vm.form, "isCar", $$v);
      },
      expression: "form.isCar"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('v-row', {
    staticClass: "mt-3"
  }, _vm._l(Object.values((_vm$form = _vm.form) === null || _vm$form === void 0 ? void 0 : _vm$form.files), function (file, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "6"
      }
    }, [_c('div', [_c('span', [_vm._v(_vm._s(`첨부파일 ${index + 1}: `))]), file !== null && file !== void 0 && file.name ? _c('span', {
      staticStyle: {
        "font-weight": "bold"
      }
    }, [_vm._v(_vm._s(file === null || file === void 0 ? void 0 : file.name))]) : _c('span', {
      staticStyle: {
        "color": "red"
      }
    }, [_vm._v("첨부 파일 없음")]), _c('v-row', {
      attrs: {
        "no-gutters": ""
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "8"
      }
    }, [_c('v-file-input', {
      model: {
        value: _vm.form.files[`file${index + 1}`],
        callback: function ($$v) {
          _vm.$set(_vm.form.files, `file${index + 1}`, $$v);
        },
        expression: "form.files[`file${index + 1}`]"
      }
    })], 1), file ? _c('v-col', {
      attrs: {
        "cols": "4",
        "align-self": "center"
      }
    }, [_c('v-btn', {
      staticClass: "ml-5",
      attrs: {
        "icon": "",
        "href": file === null || file === void 0 ? void 0 : file.src,
        "target": "_blank"
      }
    }, [_c('v-icon', [_vm._v("mdi-eye")])], 1), _c('v-btn', {
      staticClass: "ml-3",
      attrs: {
        "icon": "",
        "href": file === null || file === void 0 ? void 0 : file.src,
        "download": file === null || file === void 0 ? void 0 : file.name
      }
    }, [_c('v-icon', [_vm._v("mdi-download")])], 1)], 1) : _vm._e()], 1)], 1)]);
  }), 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.cancel
    }
  }, [_vm._v("취소")]), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }