<template>
    <v-dialog v-model="show" max-width="800px">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card>
            <v-card-title>
                <span class="subtitle-1">입주 관심 상세</span>
            </v-card-title>
            <v-divider />

            <v-card-text>
                <v-row class="mt-3">
                    <v-col class="py-0">
                        <v-text-field v-model="form.name" label="이름" hide-details class="mt-3"></v-text-field>
                    </v-col>
                    <v-col class="py-0">
                        <v-text-field v-model="form.birth" label="생년월일" hide-details class="mt-3"></v-text-field>
                    </v-col>
                    <v-col class="py-0">
                        <v-text-field v-model="form.phone" label="연락처" hide-details class="mt-3"></v-text-field>
                    </v-col>
                    <v-col class="py-0">
                        <v-text-field v-model="form.email" label="이메일" hide-details class="mt-3"></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="mt-3">
                    <v-col class="py-0">
                        <v-text-field v-model="form.spouse.name" label="배우자 이름" hide-details class="mt-3"></v-text-field>
                    </v-col>
                    <v-col class="py-0">
                        <v-text-field v-model="form.spouse.phone" label="배우자 연락처" hide-details class="mt-3"></v-text-field>
                    </v-col>
                    <v-col class="py-0">
                        <v-text-field v-model="form.spouse.birth" label="배우자 생년월일" hide-details class="mt-3"></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider />
            <v-card-text>
                <v-row class="mt-3">
                    <v-col class="py-0">
                        <v-select v-model="form.qualification_type" :items="qualification_items" label="신청자격" hide-details class="mt-3" />
                    </v-col>
                    <v-col class="py-0">
                        <v-select v-model="form.supply_category" :items="category_items" label="공급유형" hide-details class="mt-3" />
                    </v-col>
                    <v-col class="py-0">
                        <v-select v-model="form.supply_type" :items="type_items" label="공급타입" hide-details class="mt-3" />
                    </v-col>
                    <v-col class="py-0">
                        <v-select v-model="form.earning_rank" :items="rank_items" label="소득순위" hide-details class="mt-3" />
                    </v-col>
                    <v-col class="py-0">
                        <v-select v-model="form.region_rank" :items="rank_items" label="지역순위" hide-details class="mt-3" />
                    </v-col>
                </v-row>
                <v-row class="mt-3">
                    <v-col class="py-0">
                        <v-select v-model="form.isHomeless" :items="boolean_items" label="무주택 충족 여부" hide-details class="mt-3" />
                    </v-col>
                    <v-col class="py-0">
                        <v-select v-model="form.isAsset" :items="boolean_items" label="자산기준 충족 여부" hide-details class="mt-3" />
                    </v-col>
                    <v-col class="py-0">
                        <v-select v-model="form.isCar" :items="boolean_items" label="자동차가액 충족 여부" hide-details class="mt-3" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider />
            <v-card-text>
                <v-row class="mt-3">
                    <v-col cols="6" v-for="(file, index) in Object.values(form?.files)" :key="index">
                        <div>
                            <span>{{ `첨부파일 ${index + 1}: ` }}</span>
                            <span v-if="file?.name" style="font-weight: bold">{{ file?.name }}</span>
                            <span v-else style="color: red">첨부 파일 없음</span>
                            <v-row no-gutters>
                                <v-col cols="8">
                                    <v-file-input v-model="form.files[`file${index + 1}`]"></v-file-input>
                                </v-col>
                                <v-col v-if="file" cols="4" align-self="center">
                                    <v-btn class="ml-5" icon :href="file?.src" target="_blank"><v-icon>mdi-eye</v-icon></v-btn>
                                    <v-btn class="ml-3" icon :href="file?.src" :download="file?.name"><v-icon>mdi-download</v-icon></v-btn>
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="cancel">취소</v-btn>
                <v-btn color="primary" text @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { BOOLEAN_LIST, initApplication, QUALIFICATION_TYPE, RANKING_LIST, SUPPLY_CATEGORY, SUPPLY_TYPE } from "@/assets/variables";

export default {
    props: {
        application: { type: Object, default: initApplication() },
    },
    data() {
        return {
            show: false,
            form: initApplication(),
            category_items: Object.values(SUPPLY_CATEGORY),
            qualification_items: Object.values(QUALIFICATION_TYPE),
            type_items: Object.values(SUPPLY_TYPE),
            rank_items: Object.values(RANKING_LIST),
            boolean_items: Object.values(BOOLEAN_LIST),
        };
    },
    mounted() {
        this.sync();
    },
    methods: {
        sync() {
            this.form = initApplication(this.application);
        },
        async save() {
            try {
                let { files, ...form } = this.form;

                let { application } = await api.console.application.put(form);

                let { file1, file2, file3, file4 } = files;
                let { file1: befFile1 = undefined, file2: befFile2 = undefined, file3: befFile3 = undefined, file4: befFile4 = undefined } = application?.files || {};

                file1 = await this.processFile({ file: file1, befFile: befFile1 }, application._id, "file1");
                file2 = await this.processFile({ file: file2, befFile: befFile2 }, application._id, "file2");
                file3 = await this.processFile({ file: file3, befFile: befFile3 }, application._id, "file3");
                file4 = await this.processFile({ file: file4, befFile: befFile4 }, application._id, "file4");

                await api.console.application.put({ _id: application._id, files: { file1, file2, file3, file4 } });

                alert("수정되었습니다.");
                this.show = false;
                this.$emit("update");
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        async processFile({ file, befFile = undefined }, _application, name) {
            if (befFile?.src && file?.src !== befFile?.src) {
                await api.console.application.upload.delete({ _id: _application, name });
            }
            if (file && file instanceof File) {
                return (await api.console.application.upload.post(_application, file, name))?.application?.files[name];
            }

            return file || null;
        },
        cancel() {
            this.show = false;
        },
    },
    watch: {
        application() {
            this.sync();
        },
    },
};
</script>

<style>
</style>